<template>
  <div>
    <Header></Header>
    <!-- 大图 -->
    <div class="dt">
      <div class="dt_title">彼思零售 · 让门店运营更智能</div>
      <div class="dt_subtitle">专注于中小零售门店服务</div>
    </div>

    <!-- 软硬件一体 -->
    <div class="ryjyt">
      <div class="ryjyt_title">软硬件一体化</div>
      <div class="ryjyt_subtitle">开店省心，门店设备一站式配齐</div>
      <div class="ryjytsbf">
        <div class="ryjyt_left">
          <div class="ryjyt_dt">
            <img :src="now_url" alt="" />
          </div>
          <div class="ryjyt_st">
            <span v-for="item of ryjytl" :key="item.key"
              ><img :src="item.url" alt=""
            /></span>
          </div>
        </div>
        <div class="ryjyt_right">
          <div class="ryjyt_right_text">
            <div class="pb35 fs28">智能门店硬件一站式配齐</div>
            <p class="fs16 lh30 pb40">
              收银机：15.6寸屏幕尺寸升级 单双屏任你选
              <br />

              客流安防：白天管店 晚上看店
              <br />
              广告机：高清画质 广告传播
            </p>
            <p class="c5f8 fs16">更多组合，按需搭配</p>
            <div class="ryjyt_right_st">
              <span v-for="item of ryjytr" :key="item.key"
                ><img :src="item.url" alt=""
              /></span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 收银 -->
    <div class="sy">
      <div class="sy_all">
        <div class="sy_left">
          <div class="fs28 pb35 pt70">会收银，更懂门店营销的收银系统</div>
          <p class="lh30 fs16 pb40">
            商品一键建档，收银高效快捷，支持离线使用<br />
            销售库存自动更新，解决门店管理混乱，轻松管理进销存<br />
            自建会员体系，个性化会员营销插件，提高复购率<br />
            门店经营报表，门店决策由数据决定
          </p>
          <img
            src="http://www.zhonglunnet.com/templets/zlnet/images/fasymdimg.jpg"
            alt=""
          />
          <p>
            <span class="mr80">商品营销</span><span class="mr60">会员营销</span>
            <span>店铺品牌营销</span>
          </p>
        </div>
        <div class="sy_right pt70">
          <img
            class="mb30"
            src="http://14.116.154.181:8890/bisi_website/gw/syposimg1.jpg"
            alt=""
          />
          <p class="tac">* 支持：安卓、windows</p>
        </div>
      </div>
    </div>

    <!-- 增值服务 -->
    <div class="zzfw">
      <div class="fs28 pb20 pt50 tac">增值服务</div>
      <div class="fs16 pb50 cb6 tac">扩大门店营收，帮助商户多赚钱</div>
      <div class="zzfw_text">
        <div class="zzfw_left">
          <img
            class="mb30"
            src="http://www.zhonglunnet.com/templets/zlnet/images/faimg11.jpg"
            alt=""
          />
        </div>
        <div class="zzfw_right ml100">
          <p class="fs26 pb45">供应链爆品服务</p>
          <p class="lh25 pr c92 pb10">
            <img
              class="pa t4"
              src="http://www.zhonglunnet.com/templets/zlnet/images/faicon1.png"
              alt=""
            />
            每周为商户提供两款供应链商品，对爆款商品进行卖点提炼、商品定价、商品图文包装（包含主图和详情图）
          </p>
          <p class="lh25 pr c92 pb10">
            <img
              class="pa t4"
              src="http://www.zhonglunnet.com/templets/zlnet/images/faicon1.png"
              alt=""
            />
            社群搭建、社群营销、社群运营培训、商品玩法培训、爆款商品利润分成
          </p>
        </div>
      </div>
    </div>

    <!-- 门店营销策划 -->
    <div class="mdys">
      <div class="mdys_text">
        <div class="mdys_left">
          <div class="fs28 pb40 pt90">门店营销活动策划</div>
          <div class="lh25 pb40 c92">
            针对门店分析，根据门店运营现状及商户预期需求，并对此提供相应的门店活动计划，针对活动运营状况进行数据统计分析，进行品牌运营分析
          </div>
          <img
            src="http://www.zhonglunnet.com/templets/zlnet/images/yxhdimg2.jpg"
            alt=""
          />
          <p>
            <span class="mr52">开业活动</span><span class="mr56">店庆活动</span
            ><span class="mr52">会员日</span><span>节日活动</span>
          </p>
        </div>
        <div class="mdys_right pt50">
          <img
            src="http://www.zhonglunnet.com/templets/zlnet/images/faimg12.png"
            alt=""
          />
        </div>
      </div>
    </div>

    <!-- 微信小程序 -->
    <div class="wxxcx">
      <div class="wxxcx_left pt45">
        <img
          src="http://www.zhonglunnet.com/templets/zlnet/images/faimg13.jpg"
          alt=""
        />
      </div>
      <div class="wxxcx_right pt45">
        <div class="fs26 pb30 tac">微信商城&小程序系统搭建</div>
        <div class="tac pb55">从线上到线下，轻松升级</div>
        <div>
          <table class="c92">
            <tbody>
              <tr>
                <td>
                  <ul>
                    <li class="mb20">
                      <img
                        src="http://www.zhonglunnet.com/templets/zlnet/images/faicon2.png"
                        alt=""
                      />
                      微信公众号申请
                    </li>
                    <li class="mb20">
                      <img
                        src="http://www.zhonglunnet.com/templets/zlnet/images/faicon2.png"
                        alt=""
                      />
                      微信商城配置
                    </li>
                    <li class="mb20">
                      <img
                        src="http://www.zhonglunnet.com/templets/zlnet/images/faicon2.png"
                        alt=""
                      />
                      商品配送服务体系搭建
                    </li>
                  </ul>
                </td>
                <td>
                  <ul>
                    <li class="mb20">
                      <img
                        src="http://www.zhonglunnet.com/templets/zlnet/images/faicon2.png"
                        alt=""
                      />
                      公众号配置
                    </li>
                    <li class="mb20">
                      <img
                        src="http://www.zhonglunnet.com/templets/zlnet/images/faicon2.png"
                        alt=""
                      />
                      会员营销体系搭建
                    </li>
                    <li class="mb20">
                      <img
                        src="http://www.zhonglunnet.com/templets/zlnet/images/faicon2.png"
                        alt=""
                      />
                      微商品运营基础培训
                    </li>
                  </ul>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <!-- 合作资询 -->
    <div class="hzzx">
      <div class="hzzx_text">
        <div class="fs30 c293 pt50">便利商超解决方案合作咨询</div>
        <div class="fs16 cb6 pt15 pb45">在线申请，迈出第一步</div>
        <el-form ref="form" :model="form" label-width="80px">
          <el-form-item label="姓名：">
            <el-input v-model="form.name"></el-input>
          </el-form-item>
          <el-form-item label="电话：">
            <el-input v-model="form.phone"></el-input>
          </el-form-item>
          <el-form-item label="城市：">
            <el-input v-model="form.phone"></el-input>
          </el-form-item>
          <el-form-item label="备注：">
            <el-input type="textarea" rows="5" v-model="form.phone"></el-input>
          </el-form-item>
          <el-form-item size="large">
            <el-button type="primary">立即申请</el-button>
          </el-form-item>
        </el-form>
        <div class="c093 fs12 tac">
          *
          您的信息将被严格保密，信息提交后，区域经理会在2小时内与您联系，请留意接听电话
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Header from "@/components/Header";
import Footer from "@/components/Footer";
export default {
  name: "supermarket",
  data() {
    return {
      form: {
        name: "",
        phone: "",
        city: "",
        des: "",
      },
      // 软硬件一体左边图片
      now_url: "http://14.116.154.181:8890/bisi_website/gw/fnaniimg1.jpg",
      ryjytl: [
        {
          index: 1,
          url: "http://www.zhonglunnet.com/templets/zlnet/images/fnaniimg1.jpg",
        },
        {
          index: 2,
          url: "http://www.zhonglunnet.com/templets/zlnet/images/fnaniimg2.jpg",
        },
        {
          index: 3,
          url: "http://www.zhonglunnet.com/templets/zlnet/images/fnaniimg3.jpg",
        },
      ],

      //   软硬件一体右边的图片
      ryjytr: [
        {
          index: 1,
          url: "http://www.zhonglunnet.com/templets/zlnet/images/faythimg1.jpg",
        },
        {
          index: 2,
          url: "http://www.zhonglunnet.com/templets/zlnet/images/faythimg2.jpg",
        },
        {
          index: 3,
          url: "http://www.zhonglunnet.com/templets/zlnet/images/faythimg3.jpg",
        },
      ],
    };
  },
  components: { Header, Footer },
};
</script>

<style scoped>
.dt {
  height: 407px;
  width: 100%;
  background-image: url("http://www.zhonglunnet.com/templets/zlnet/images/fabanner.jpg");
  background-repeat: no-repeat;
  background-size: auto 100%;
  background-position: top center;
}
.dt_title {
  font-size: 40px;
  padding-top: 115px;
  color: #ffffff;
}
.dt_subtitle {
  padding-top: 70px;
  font-size: 28px;
  color: #ffffff;
}

/* 软硬件一体 */
.ryjyt {
  margin: 40px 0px;
}
.ryjyt_title {
  padding: 30px 0 20px 0;
  color: #33497f;
  font-size: 30px;
}
.ryjyt_subtitle {
  font-size: 18px;
  color: #b6b6b9;
  padding-bottom: 60px;
}
.ryjytsbf {
  width: 1100px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
}
.ryjyt_left {
  width: 500px;
}
.ryjyt_right {
  width: 500px;
}
.ryjyt_right_text {
  text-align: left;
}

.ryjyt_dt {
  width: 400px;
  height: 300px;
  margin-bottom: 20px;
}
.ryjyt_st {
  width: 400px;
  height: 70px;
}
.ryjyt_st span {
  display: inline-block;
  margin: 0px 15px;
  width: 90px;
  height: 60px;
  border: 1px solid #ddd;
  border-radius: 4px;
}
.ryjyt_st img {
  margin-top: 10px;
  width: 52px;
  height: 40px;
}

.ryjyt_right_st {
  width: 500px;
  height: 70px;
}
.ryjyt_right_st span {
  display: inline-block;
  margin: 0px 30px 20px 0px;
  width: 100px;
  height: 90px;
  border: 1px solid #2d82fa;
  border-radius: 4px;
}
.ryjyt_right_st img {
  margin-top: 15px;
  margin-left: 10px;
  width: 80px;
  height: 60px;
}

/* 收银 */
.sy {
  background-color: #fafafa;
  height: 476px;
}
.sy_all {
  width: 1100px;
  display: flex;
  justify-content: space-between;
  margin: 0px auto;
}
.sy_left {
  width: 500px;
  text-align: left;
}
.sy_right {
  width: 500px;
}
/* 增值服务 */
.zzfw {
  height: 510px;
}
.zzfw_text {
  width: 1100px;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
}
.zzfw_left {
  width: 350px;
  height: 300px;
}
.zzfw_right {
  text-align: left;
  width: 550px;
}
.zzfw_right img {
  left: -25px;
}

/* 门店营销策划 */
.mdys {
  height: 424px;
  background-color: #fafafa;
}
.mdys_text {
  width: 1100px;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
}
.mdys_left {
  width: 500px;
  text-align: left;
}
/* 微信小程序 */
.wxxcx {
  width: 1100px;
  height: 430px;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
}
.wxxcx_right {
  width: 550px;
}
.wxxcx_right table tr td {
  width: 275px;
  text-align: left;
}
.wxxcx_right table tr td ul li {
  list-style: none;
}
/* 合作资询 */
.hzzx {
  background: #fafafa;
}
.hzzx_text {
  width: 800px;
  height: 600px;
  margin: 0 auto;
}
.hzzx .el-form {
  margin: 0 auto;
  width: 600px;
}
</style>